import { DateTime, IANAZone, Settings } from "luxon"
import { baseURL, s3BaseURL } from "../../../../etc/settings"
import { I191ArtistProfile, IPageWrapper } from "../../../../etc/types"
import { cityTeamLogos } from "../../city-edition/23-24/models/enums"

export const assetsBaseURL = s3BaseURL + "assets/custom_pages/191-collabs/"
export const staticAssetsBaseURL = assetsBaseURL + "static/"
export const seasonBaseURL = assetsBaseURL + "2024-25/"

export const artistCallImageBaseURL =
	s3BaseURL + "assets/custom_pages/191-collabs/artist_call/2024-25/img/"
export const artistCallVideoBaseURL =
	s3BaseURL + "assets/custom_pages/191-collabs/artist_call/2024-25/video/"

export const fanInterestFormURL =
	"https://memphisbasketball.formstack.com/forms/2023_24_191_collabs_game_pack_interest_form"
export const artistInterestFormURL =
	"https://memphisbasketball.formstack.com/forms/2023_24_191_collabs_artist_interest_form"
export const grizzliesEnewsFormUrl =
	"https://memphisbasketball.formstack.com/forms/grizzlies_enews_signup"

const staticData: IPageWrapper = {
	title: "191 Collabs",
	description:
		"191 Collabs, presented by Hennessy, celebrates our city’s unique voice and vibe through one-of-a-kind Grizz gear. Showcasing designer streetwear to graphic tees all designed by local Memphis creatives. At select Grizzlies games this season, fans at FedExForum will be able to shop these limited drops at the Grizzlies Den.",
	pageImage: assetsBaseURL + "_240209_191_In-Store-Signage_22x28_191_Hennesy.jpg",
	pageLink: baseURL + "191-collabs"
}

export default staticData

export const pastArtists = [
	{ name: "Good Hippy" },
	{ name: "ODDS Atelier" },
	{ name: "Prep Curry" },
	{ name: "Rowdy Dept. & Immortal Ink" },
	{ name: "Unapologetic" },
	{ name: "Alexis Miché" }
]

export const news = [
	{
		name: "Grizzlies partner with painter behind popular Ja Morant tees.",
		outlet: "daily memphian",
		date: "Mar. 10, 2024",
		link:
			"https://dailymemphian.com/article/41807/grizzlies-partner-with-painter-behind-popular-ja-morant-tees?utm_source=site_search&utm_medium=link&utm_campaign=site_search"
	},

	{
		name: "Bluff City’s Black designers bring fashion to the forefront.",
		outlet: "memphis flyer",
		date: "Nov. 8, 2023",
		link: "https://www.memphisflyer.com/street-style"
	}
]

export const artistProfiles: I191ArtistProfile[] = [
	{
		id: "good_hippy",
		name: "Good Hippy",
		title: "It All Comes Together",
		description: `
			<p>Life is a process, a journey. No moment is a waste, the little ones, the big ones, the ones that feel insignificant; they are all about a bigger picture. This collection is inspired by the wins, the losses, the promotions, the injuries, the blessings, the mountaintops, the moments where it feels like it can’t get any better than this, and the moments where we feel like our shortcomings are overcoming. From Vancouver all the way to Memphis. From contenders to the draft lottery.  From the sherpa ¼ zip, inspired by my time in suburban high school, to the airbrush tee that takes me back to ‘05 sitting in my pops car, every moment has led me here. Whether it’s the lows or the highs, this collection serves as a reminder that in due time, eventually, <strong>It All Comes Together.</strong></p>
		`,
		logo: "designers/hippy/img/designer-logo.png",
		collection_logo: "designers/hippy/img/collection-logo.png",
		profile_link:
			process.env.NODE_ENV === "development"
				? "/191collabs/good_hippy"
				: baseURL + "191collabs/good_hippy",
		datetime: "2024/11/06 19:00",
		vs_at: "vs",
		opponent: {
			city: "Los Angeles",
			name: "Lakers",
			logo: cityTeamLogos.LAL
		},
		tixlink:
			"https://www.ticketmaster.com/event/1B00610CB7D52476?brand=grizzlies&artistid=806038&wt.mc_id=NBA_TEAM_MEM_SINGLE_GM_TIX_PG_GM5&utm_source=Grizzlies.com&utm_medium=client&utm_campaign=Single_Game_Tickets&utm_content=SINGLE_GM_TIX_PG_GM5",
		bios: [
			{
				name: "Good Hippy",
				title: "",
				description: [
					`Miles Thomas (hippy) is a creative and streetwear designer from Memphis, TN with the goal of being recognized in multiple venues.  He is willing to go above and beyond in order to achieve the given purpose. Creatively known as Hippy, he has an online boutique specializing in streetwear for the everyday. Hippy’s designs have been worn by artists like PJ Morton and Kenneth Whalum III. His designs have been recognized on the NBA’s X Feed and worn by many different athletes. He has been called on to work with artists like Masego, Wale, and Bryson Tiller on special collaborations and projects.`,
					`Hippy is a consistent collaborator of the Memphis Grizzlies and their 191 Collabs program.  His first two collections “Sec 118” and “In Due Time” were massive hits among Grizzlies and streetwear fans alike and he’s excited to launch his third collection “It All Comes Together,” this season. His outlook on design has always been based on telling stories and his personal connections. He believes streetwear isn’t just about personal style but our own unique stories as well.`
				],
				weblink: "https://www.hippyisgood.com/",
				twitter: "https://twitter.com/hippyisgood",
				insta: "https://instagram.com/goodhippy",
				facebook: "",
				tiktok: "",
				youtube: "",
				img: "designers/hippy/img/designer-photo.jpg"
			}
		]
	},
	{
		id: "m_town",
		name: "M-Town",
		title: "Respect My City",
		description: `
			<p>The "Respect My City Collection" is a nostalgic homage to the bold sportswear trends of the 80s and 90s. While growing up during that time, Memphis did not have a pro basketball team to support. It was also hard to find any kind of quality Memphis branded athletic gear outside of a few local stores. Walking into a big box store and seeing athletic gear catered to neighboring states and cities while only stocking a few Memphis branded pieces always got under my skin. The "Respect My City Collection" was born out of that frustration and longing—a tribute to those who have felt the same sting of being overlooked and undervalued.</p>
		`,
		logo: "designers/m_town/img/designer-logo.png",
		collection_logo: "designers/m_town/img/collection-logo.png",
		collection_images: [
			seasonBaseURL + "designers/m_town/img/collection/m-town-baseball.png",
			seasonBaseURL + "designers/m_town/img/collection/m-town-jacket.png",
			seasonBaseURL + "designers/m_town/img/collection/m-town-tshirt.png",
			seasonBaseURL + "designers/m_town/img/collection/m-town-jersey.png"
		],
		profile_link:
			process.env.NODE_ENV === "development" ? "/191collabs/m_town" : baseURL + "191collabs/m_town",
		datetime: "2024/11/29 16:00",
		vs_at: "vs",
		opponent: {
			city: "New Orleans",
			name: "Pelicans",
			logo: cityTeamLogos.NOP
		},
		tixlink:
			"https://www.ticketmaster.com/event/1B00610CB7F424FC?brand=grizzlies&artistid=806038&wt.mc_id=NBA_TEAM_MEM_SINGLE_GM_TIX_PG_GM12&utm_source=Grizzlies.com&utm_medium=client&utm_campaign=Single_Game_Tickets&utm_content=SINGLE_GM_TIX_PG_GM12",
		bios: [
			{
				name: "Brannon Hobbs",
				title: "",
				description: [
					`I’m a proud Memphian, born and raised. The 80s and 90s were particularly special to me, and while I thrive on nostalgia, I also embrace progress and forward-thinking for the city I love. I created M-Town Merchandise to offer the type of Memphis-branded athletic wear that I couldn't find in big box stores. With over 20 years of design experience, I aimed to create bold, commanding designs that blend a classic athletic feel with genuine Memphis influence. One of my favorite sights at sporting events or around town is seeing someone wearing uniquely Memphis-branded apparel that makes you ask, 'Where’d you get that?!' Those are the pieces I love. I wanted to capture that feeling and infuse it into everything created for M-Town.`,
					`Living in Memphis, the pride we have for our city is hard to put into words. Despite our challenges, we can envision the greatness we could achieve if things align properly. Memphis has always been an underdog city—rough around the edges but brimming with talent and culture. Our music, food, dance, vernacular, and visual artistry are emulated worldwide, though often without the recognition we deserve. Our sports programs are among the best in the country but rarely get the acknowledgment they merit. M-Town Merchandise is a way to proudly showcase our love for the city. Our culture has undoubtedly influenced the world; respect is paramount. Give us ours.`
				],
				weblink: "https://www.mtownmerch.com/",
				twitter: "",
				insta: "https://instagram.com/mtown_merch",
				facebook: "https://www.facebook.com/mtownmerch",
				tiktok: "",
				youtube: "",
				img: "designers/m_town/img/designer-photo.jpg"
			}
		]
	},
	{
		id: "prep_curry",
		name: "Prep Curry x J13",
		title: "",
		description: `

		`,
		logo: "designers/prep_curry/img/designer-logo.png",
		collection_logo: "",
		profile_link:
			process.env.NODE_ENV === "development"
				? "/191collabs/prep_curry"
				: baseURL + "191collabs/prep_curry",
		datetime: "2024/12/19 19:00",
		vs_at: "vs",
		opponent: {
			city: "Golden State",
			name: "Warriors",
			logo: cityTeamLogos.GSW
		},
		tixlink:
			"https://www.ticketmaster.com/event/1B00610CB96727CA?brand=grizzlies&artistid=806038&wt.mc_id=NBA_TEAM_MEM_SINGLE_GM_TIX_PG_GM15&utm_source=Grizzlies.com&utm_medium=client&utm_campaign=Single_Game_Tickets&utm_content=SINGLE_GM_TIX_PG_GM15",
		bios: [
			{
				name: "Jaren Jackson Jr.",
				title: "",
				description: [
					`Jaren Jackson Jr. is an American professional basketball player for the Memphis Grizzlies of the National Basketball Association. On the court, Jackson Jr. is one of the greatest defensive players while also being a prolific shot creator and offensive threat. Off the court, he is a businessman, artist, philanthropist, and dedicated son who levels the playing field for everyone.`,
					`After playing one year at Michigan State, Jackson Jr. was the fourth overall pick in the 2018 NBA Draft. He became a corner stone of the franchise by being named to the NBA AllRookie First Team and leading his team to playoffs in the 2020-2021, 2021-2022 and 2022-2023 seasons. Most recently, Jackson Jr. was named to the 2023 NBA All-Star team and was crowned 2023 Defensive Player of the Year. He led the league with three blocks per game that season.`,
					`Jackson Jr. is an NBA All-Star, NBA Defensive Player of the Year, 2x NBA All-Defensive First Team recipient, 2X NBA Blocks Leader, NBPA Vice President, Big Ten Freshman of the Year and McDonald’s All-American. His current and past brand partnerships include Nike, NBA2K, Starry, Paco Rabanne, Panini, Fanatics, Whistle, Dove, Puig, and more.`,
					`Off the court, Jaren is dedicated to finding meaningful ways to create positive change in his community. He has consistently used his platform for voter education, to increase awareness around election participation, the importance of vaccinations around the COVID-19 pandemic and more. Since 2021, Jackson Jr. has led a social media fundraising campaign for Giving Tuesday to support organizations in Memphis. In December 2021, he won the NBA Cares Community Assist Award and was a finalist for the Kareem Abdul-Jabbar NBA Social Justice Champion Award.`,
					`Jackson Jr. is a recording artist who goes by the stage name, Trip J. In 2023, he released his latest studio album, KND, headlined by hit song Cucamonaga.`
				],
				weblink: "",
				twitter: "",
				insta: "https://www.instagram.com/jaren/",
				facebook: "",
				tiktok: "",
				youtube: "",
				img: "designers/prep_curry/img/jjj.jpg"
			},
			{
				name: "Prep Curry",
				title: "",
				description: [
					`When Prep Curry arrived in Los Angeles, California with only $6 to work on his dream of becoming an accomplished fashion designer, he had no idea where his journey would lead. 7 years later, history has been made as Prep Curry has grown his craft from creating bowties and pocket squares to become the first African American designer to partner with Banana Republic to release a collection. The collection, which has been available online and at select Banana Republic locations, completely sold out with only a few styles under 4 days after the release.`,
					`The Banana Republic x Prep Curry collaboration features bold floral patterns in the dual gender capsule and is the first of the company’s efforts to highlight creatives and support emerging designers. Some of the items included in the collection are joggers, crew-neck t-shirts, sneakers, and accessories including duffel bags and backpacks.`,
					`Prep brings his signature style to any piece that he designs, believing that fashion should be an expression of a person’s daily mood. As a self-taught designer, he received a sewing machine for his birthday in 2009 as a newfound interest and since his relocation from Memphis, Tennessee, to Los Angeles, California, has worked with celebrities such as Tank, Sherry Shepherd, Brandy, Elise Neal, and more. Now getting the chance to work with hometown basketball team the grizzles makes it 10 times better.`
				],
				weblink: "https://prepcurry.com/",
				twitter: "",
				insta: "https://www.instagram.com/prepcurry",
				facebook: "",
				tiktok: "",
				youtube: "",
				img: "designers/prep_curry/img/prep_curry.jpg"
			}
		]
	},
	{
		id: "drumatized",
		name: "Drumatized",
		title: "",
		description: `
			<p>Established by Grammy-nominated producer, Tay Keith and manager, Cambrian Strong, DRUMATIZED is a record label renowned for its diverse array of highly skilled producers and songwriters specializing across multiple genres. DRUMATIZED’s mission is to develop talent in the music industry, with a focus on innovative creativity, pushing boundaries, and creating meaningful connections between artists, producers and audiences worldwide. Collaborating with top-tier artists globally, DRUMATIZED has played a pivotal role in artist development and shaping the careers of numerous talents.</p>
			<p class="socials"><a href="https://www.drumatized.com/" target="_blank" rel="noreferrer">drumatized.com</a><a href="https://www.instagram.com/drumatized" target="_blank" rel="noreferrer">instagram.com/drumatized</p>
		`,
		logo: "designers/drumatized/img/designer-logo.png",
		collection_logo: "",
		profile_link:
			process.env.NODE_ENV === "development"
				? "/191collabs/drumatized"
				: baseURL + "191collabs/drumatized",
		datetime: "2025/01/24 19:00",
		vs_at: "vs",
		opponent: {
			city: "New Orleans",
			name: "Pelicans",
			logo: cityTeamLogos.NOP
		},
		tixlink:
			"https://www.ticketmaster.com/event/1B00610CB94526F9?brand=grizzlies&artistid=806038&wt.mc_id=NBA_TEAM_MEM_SINGLE_GM_TIX_PG_GM22&utm_source=Grizzlies.com&utm_medium=client&utm_campaign=Single_Game_Tickets&utm_content=SINGLE_GM_TIX_PG_GM22",
		bios: [
			{
				name: "Tay Keith",
				title: "",
				description: [
					`Tay Keith, a Grammy-nominated, two-time BMI 'Producer of the Year' from Memphis, Tennessee. He gained local fame producing tracks for Memphis rappers like Yo Gotti and Moneybagg Yo before his breakout hit, BlocBoy JB and Drake's "Look Alive," brought him worldwide recognition. Tay Keith's biggest success, Travis Scott and Drake’s "SICKO MODE," peaked at #1 on the Billboard Hot 100 and earned Grammy nominations. He has also achieved major success producing for rising star Sexyy Red, further solidifying his impact on the industry. Tay Keith has expanded his reach beyond music with investments in various companies, further establishing himself as an influential entrepreneur.`
				],
				weblink: "https://www.drumatized.com/",
				twitter: "",
				insta: "https://www.instagram.com/taykeith/",
				facebook: "",
				tiktok: "",
				youtube: "",
				img: "designers/drumatized/img/taykeith.jpg"
			},
			{
				name: "Tyland Jackson",
				title: "",
				description: [
					`Tyland Jackson, a visionary Creative Consultant from Memphis, seamlessly blends business acumen with an innate understanding of customer desires. With a dynamic background in fashion design, Tyland brings a rare fusion of artistic brilliance and technical mastery, crafting captivating designs that define excellence in the fashion industry. Known for effective communication, creative problem-solving, and meticulous management, Tyland thrives in team collaboration to conquer even the most audacious objectives.`
				],
				weblink: "https://www.drumatized.com/",
				twitter: "",
				insta: "https://www.instagram.com/therealtyland/",
				facebook: "",
				tiktok: "",
				youtube: "",
				img: "designers/drumatized/img/tyland.jpg"
			},
			{
				name: "Cambrian Strong",
				title: "",
				description: [
					`Cambrian Strong is a successful talent manager and entrepreneur, best known for co-founding Drumatized with business partner, Tay Keith. His clients have produced for top artists including Drake, Beyoncé, and Travis Scott, and producing global hits like "Get It Sexyy," "Rich Flex," and "Jimmy Cooks." Cambrian also founded Reflection Theory, a management company focused on developing creatives, further solidifying his commitment to nurturing new talent and expanding influence in the music industry.`
				],
				weblink: "https://www.drumatized.com/",
				twitter: "",
				insta: "https://www.instagram.com/cambriian/",
				facebook: "",
				tiktok: "",
				youtube: "",
				img: "designers/drumatized/img/cambrian.jpg"
			}
		]
	},
	{
		id: "unapologetic",
		name: "Unapologetic.",
		title: "",
		description: ``,
		logo: "designers/unapologetic/img/designer-logo.png",
		collection_logo: "",
		profile_link:
			process.env.NODE_ENV === "development"
				? "/191collabs/unapologetic"
				: baseURL + "191collabs/unapologetic",
		datetime: "2025/02/28 19:00",
		vs_at: "vs",
		opponent: {
			city: "New York",
			name: "Knicks",
			logo: cityTeamLogos.NYK
		},
		tixlink:
			"https://www.ticketmaster.com/event/1B00610CB95E2787?brand=grizzlies&artistid=806038&wt.mc_id=NBA_TEAM_MEM_SINGLE_GM_TIX_PG_GM28&utm_source=Grizzlies.com&utm_medium=client&utm_campaign=Single_Game_Tickets&utm_content=SINGLE_GM_TIX_PG_GM28",
		bios: [
			{
				name: "IMAKEMADBEATS",
				title: "CEO/Visionary of UNAPOLOGETIC.",
				description: [
					`IMAKEMADBEATS – born James Dukes – is a Memphis native, having grown up immersed in the city’s unmistakable sonic identity. But despite the definitive identity of the region, he often felt disconnected from what creative paths were supported there. After leaving his home to find infrastructure and creative synergy, he would eventually end up in Manhattan’s legendary Quad Studios, where he became a go-to ghost producer and found a new niche in scoring for film and television.`,
					`He went on to provide music for many companies, including NBC, ABC, HBO, DC Shoes, Oxygen, and more. Inspired by the idea that music could speak without words – a theme that he would uphold years later with his debut instrumental EP Better Left Unsaid – he further developed the IMAKEMADBEATS persona and mask. This led him to work with the who’s-who of the indie underground scene in the ‘00s on wax, in the studio, and on stage, including Black Milk, Oh No, Talib Kweli, Solange Knowles, Ludacris, and more.`,
					`Upon returning to Memphis years later, IMAKEMADBEATS discovered he was no longer the only odd man out in his home. “I found other people that felt like they had no representation creatively, and they too felt like they had to leave,” he says. “I realized that people will never know that others share their outlook if they feel suppressed and don’t speak on things that could possibly alienate them. Understanding that need was the start of Unapologetic.” In 2015, IMAKEMADBEATS founded Unapologetic, a company/label that would not only change the landscape of his career as a producer, but also would focus on shifting the culture of the community he was raised in.`,
					`The idea was simple: create a forward-thinking & culture based organization that allowed creatives of all kinds to represent themselves and their ideas unapologetically. The multifaceted organization included music production/distribution, videography, merchandising, event coordination, and the Unapologetic World App, an app designed by IMAKEMADBEATS himself as a platform for Unapologetic’s content and it’s Uncover journalism series.`,
					`After months of organizing, strategic moves and dreaming, UNAPOLOGETIC., alongside partner nonprofit organization TONE, purchased 7 acres of land and a 230 ft tower in Orange Mound with the purpose of building a hub for black business and innovation in the first neighborhood built for and by black people. The development was featured in media outlets all over the country, including The NY Times and Black Enterprise. This establishment is structured to empower the community to be self sustaining economically. The announcement of the development alongside consistent engagement in the community awarded James and Victoria Jones (Executive Director of TONE) as “Memphians of The Year” by Memphis Magazine.`
				],
				weblink: "https://www.weareunapologetic.com/imakemadbeats",
				twitter: "",
				insta: "https://www.instagram.com/imakemadbeats",
				facebook: "",
				tiktok: "",
				youtube: "",
				img: "designers/unapologetic/img/imakemadbeats.jpg"
			},
			{
				name: "C MaJor",
				title: "Financial Analyst/Customer Service Manager for GARMENTS.",
				description: [
					`Born and raised in Memphis, music has always been a part of C Major’s life. His mother would often lull him to sleep with classical selections as an infant. From high school throughout college he would balance performing as a percussionist in marching band and producing/engineering the legendary Project Pat. As a music producer/engineer his roots as a percussionist play a major part in the C MaJor sound. Drums are EVERYTHING. Drums hold a certain power in music; the power to make the listener feel and react.`,
					`C MaJor plays on that power using the drums in unexpected patterns to get unexpected results out of listeners. As a kid he grew up listening to the underground sound that Memphis has since taken worldwide. He has worked with hometown legends Gangsta Boo and Project Pat closely as both a producer and engineer. He is currently working with the lineage of that rich heritage as the children of Pat, known as the Slick Mouf Click, gear to stamp their name on this industry. He is also one out of three producer/engineers in the UNAPOLOGETIC. family.`,
					`To put it simply, UNAPOLOGETIC. is a multi-faceted company made up of extremely driven creatives that use their artistic superpowers to promote vulnerability. This movement is led by the living legend IMAKEMADBEATS. At the core, C MaJor is a simple, quiet, calm person. He’ll let the beats do the talking, and they talk a lot.`
				],
				weblink: "https://www.weareunapologetic.com/c-major",
				twitter: "",
				insta: "https://www.instagram.com/cmajorslodown",
				facebook: "",
				tiktok: "",
				youtube: "",
				img: "designers/unapologetic/img/c-major.jpg"
			},
			{
				name: "Sarai",
				title: "Design Team/Graphic Designer for GARMENTS.",
				description: [
					`My name is Sarai, pronounced (SUH-RYE). Born and raised Memphian. I’m a multidisciplinary artist/designer. I have a BFA in Graphic Design. I love to create art that inspires people to keep blooming regardless of their circumstances. I love to use my art to highlight African Americans in retro, whimsical styles of art, that shows positivity, happiness and I hope that viewers feel that through the art. I love to use bold colors, flowers, animals, scenery because natural nature is beautiful to me, and I see bold colors as being visual screams.`
				],
				weblink: "https://iamsarai.com",
				twitter: "",
				insta: "https://www.instagram.com/iiam.sarai",
				facebook: "",
				tiktok: "",
				youtube: "",
				img: "designers/unapologetic/img/sarai-payne.jpg"
			},
			{
				name: "AWFM (A Weirdo From Memphis)",
				title: "President/Creative Director of GARMENTS.",
				description: [
					`AWFM (A Weirdo From Memphis) is a rapper signed with UNAPOLOGETIC, a DIY creative entity based in Memphis that specializes in bringing vulnerability and sincerity to a variety of artistic disciplines. AWFM is known to bring a shocking level of transparency to everything he touches that can very easily cause strong feelings of both inspiration and discomfort.`,
					`Along with sharing his unique perspectives on life over beats, AWFM has also been known to spread his unique perspectives through the medium of clothing as the current president of UNAPOLOGETIC GARMENTS, and collaborates with the GARMENTS team to make sure everything they produce is representative of being unapologetically “U”. AWFM comes from a deep background of participation in the world of streetwear and often brings forth ideas that feel like a unique cross between those traditional elements and perspectives only a native Memphian could provide.`
				],
				weblink: "https://www.weareunapologetic.com/awfm",
				twitter: "",
				insta: "https://www.instagram.com/awfmemphis",
				facebook: "",
				tiktok: "",
				youtube: "",
				img: "designers/unapologetic/img/awfm.jpg"
			},
			{
				name: "Nakita Spencer",
				title: "Program Manager for OUTERSPACE.",
				description: [
					`Hailing from the Hot Springs of AR, Nakita S. Spencer is Memphis, TN raised. By day, Nakita honorably serves in her 22 year (and counting) Military career. By night she wears the hat of Program Manager for the musical entity of OUTERSPACE., an UNAPOLOGETIC. Studio. Nakita holds a Bachelor's degree in Marketing that is complemented by her Master's degree in Public Relations.`,
					`She enjoys using her powers of organizing and communicating to collaborate on events in the city of Memphis. When Nakita is not building towards her DJ aspirations on the ones and twos she focuses on the things she loves most; family, food, sports, music, and let us not forget love.`
				],
				weblink: "https://www.weareunapologetic.com",
				twitter: "",
				insta: "https://www.instagram.com/nakitashante",
				facebook: "",
				tiktok: "",
				youtube: "",
				img: "designers/unapologetic/img/nakita-spencer.jpg"
			},
			{
				name: "Ariel Dibia",
				title: "Stylist / Creative Consultant for GARMENTS.",
				description: [
					`Ariel Dibia is a multifaceted creative, raised in the Memphis area. Particularly known for her online web series, STYLE VIBES, where she shops and styles local creatives in the city. Ariel spends her time in front and behind the camera in the world of content creation, creative direction, and fashion styling.`
				],
				weblink: "https://www.weareunapologetic.com",
				twitter: "",
				insta: "https://www.instagram.com/good.vibes4u",
				facebook: "",
				tiktok: "",
				youtube: "",
				img: "designers/unapologetic/img/ariel-dibia.jpg"
			}
		]
	},
	{
		id: "brezerk",
		name: "Brezerk",
		title: "Home Made",
		description: `
			<p>Introducing "Home Made," a special collection by Brezay for her clothing brand, Brezerk. This line is a heartfelt tribute to the vibrant spirit and unique culture of Memphis, the city that shaped and inspired it. "Home Made" celebrates the essence of being born and raised in a place where creativity, tradition, and innovation thrive. Memphis is renowned for its profound impact on music, fashion, dance, and cuisine, and this collection captures that inimitable spirit.</p>
			<p>Every piece in "Home Made" is infused with the authenticity and flair that defines Memphis. The collaboration with the Grizzlies further emphasizes this connection, showcasing how our city's distinctive style—from the energy of the players to the passion of the fans—is truly one-of-a-kind. The Grizzlies embody the same homegrown essence that Brezay and Brezerk stand for: a celebration of a culture that’s impossible to replicate elsewhere.</p>
			<p>The collection features designs that blend traditional craftsmanship with modern aesthetics. From bold, vibrant prints inspired by Memphis’ rich musical heritage to sleek, comfortable streetwear that reflects the city’s dynamic energy, "Home Made" brings together various elements to create a cohesive and distinctive style. Each design integrates seamlessly with the next, weaving a narrative of cultural pride and artistic expression.</p>
			<p>"Home Made" is more than just a clothing collection; it’s a testament to the originality and pride that come from being deeply rooted in a unique place. It’s a nod to the ways in which our city's culture influences and inspires, turning everyday moments into something extraordinary. Celebrate the spirit of Memphis with Brezay and Brezerk—where style and culture come together in perfect harmony. Go Brezerk, Go Grizzlies!</p>
		`,
		logo: "designers/brezerk/img/designer-logo.png",
		collection_logo: "",
		profile_link:
			process.env.NODE_ENV === "development"
				? "/191collabs/brezerk"
				: baseURL + "191collabs/brezerk",
		datetime: "2025/03/14 19:00",
		vs_at: "vs",
		opponent: {
			city: "Cleveland",
			name: "Cavaliers",
			logo: cityTeamLogos.CLE
		},
		tixlink:
			"https://www.ticketmaster.com/event/1B00610CB74823B9?brand=grizzlies&artistid=806038&wt.mc_id=NBA_TEAM_MEM_SINGLE_GM_TIX_PG_GM34&utm_source=Grizzlies.com&utm_medium=client&utm_campaign=Single_Game_Tickets&utm_content=SINGLE_GM_TIX_PG_GM34",
		bios: [
			{
				name: "Brezay",
				title: "",
				description: [
					`Hailing from Memphis, Tennessee, Brezay is a versatile artist and fashion designer who seamlessly blends music and style. Over the past six years, she has captivated audiences with her unique sound and dynamic stage presence. In addition to her music career, Brezay is the founder of the fashion brand Brezerk, which she launched at Jackson State University in 2020. Her debut collection sold out on campus in just one day, and Brezerk has since evolved into a sought-after line of streetwear that combines comfort with high-fashion luxury.`,
					`Brezay's dual passions are deeply intertwined; she often performs in her own designs, making Brezerk a staple of her on-stage persona. Her music journey includes notable achievements such as opening for Bryson Tiller and earning four television placements, with over 1,000,000 streams across platforms. She has built a strong online presence, amassing over 300,000 followers who are drawn to her authenticity and passion.`,
					`With her innovative approach to both music and fashion, Brezay is establishing herself as a rising star. Her relentless drive and growing fanbase mark her as an emerging force to watch in the industry.`
				],
				weblink: "",
				twitter: "",
				insta: "https://www.instagram.com/brezay__",
				facebook: "https://www.facebook.com/breana.mitchell.92",
				tiktok: "https://www.tiktok.com/@brezay__",
				youtube: "https://youtube.com/@brezay__",
				img: "designers/brezerk/img/designer-photo.jpg"
			}
		]
	},
	{
		id: "kia_shine_chris_p",
		name: "Kia Shine x ChrisP",
		title: "Standing On Grizzness",
		description: ``,
		logo: "designers/kia_shine_chris_p/img/kiashine_chrisp_logo.png",
		collection_logo: "",
		profile_link:
			process.env.NODE_ENV === "development"
				? "/191collabs/kia_shine_chris_p"
				: baseURL + "191collabs/kia_shine_chris_p",
		datetime: "2025/04/10 19:00",
		vs_at: "vs",
		opponent: {
			city: "Minnesota",
			name: "Timberwolves",
			logo: cityTeamLogos.MIN
		},
		tixlink:
			"https://www.ticketmaster.com/event/1B00610CB7282371?brand=grizzlies&artistid=806038&wt.mc_id=NBA_TEAM_MEM_SINGLE_GM_TIX_PG_GM39&utm_source=Grizzlies.com&utm_medium=client&utm_campaign=Single_Game_Tickets&utm_content=SINGLE_GM_TIX_PG_GM39",
		bios: [
			{
				name: "Kia Shine",
				title: "",
				description: [
					`Shine Coleman, known as “Kia Shine” & by his infamous adlib “KInfolk” is an American hip-hop artist, actor, writer, podcaster, producer, and designer from Memphis, Tennessee. He gained fame with his hit singles “Respect my Fresh,” “Stunna Frames,” and major label gold-single "So Krispy," as well as, his debut album, on Universal Motown, "Due Season." Shine co-wrote Drake's "Best I Ever Had," earning a Grammy nomination and 5 BMI Awards. He has worked with artists: Lil Wayne, T.I., T-Pain , Nelly, Glorilla, Yo Gotti, Skinny Pimp, LaChat and more.`,
					`Shine is CEO of Rap Hustlaz and was awarded the Southern Entertainment Award for ‘Best Underground Artist.’ In addition to music, Shine is a devoted husband and father advocating for autism awareness. Shine & his wife Queen founded a non-profit AutismAdvocates.org. Shine has also ventured into acting, appearing in TV shows: Atlanta, STAR, Saints & Sinners and Johnson, as well as, movies: SuperFly, They Cloned Tyrone, Half Baked Totally High and soon to be released “RedOne” starring The Rock.`,
					`Shine has always had a passion for fashion, turning his popular euphemisms into fast selling hoodies, t-shirts, and hats on his positive, motivational, merch website: coffeewithkinfolk.com since 2016.`
				],
				weblink: "https://www.twitter.com/therealKiaShine",
				twitter: "",
				insta: "https://www.instagram.com/kinfolk_kiashine",
				facebook: "",
				tiktok: "",
				youtube: "",
				img: "designers/kia_shine_chris_p/img/kiashine.jpg"
			},
			{
				name: "ChrisP",
				title: "",
				description: [
					`Christian Allen known as “ChrisP” is a passionate husband, father, brand designer, brand owner, graphic artist, painter, and pianist. He has a wealth of experience in branding and a strong background in graphic design. He has demonstrated a commitment to success with “ChrisP” brand placements in his hometown of Memphis, in two malls (Oak Court Mall & Wolfchase Mall), as well as two clothing stores (The Superior Shop & Summit Clothing).`,
					`“ChrisP” is always seeking challenges and opportunities for growth. Born and raised in Memphis, Tennessee he developed a strong work ethic and love for design & art. He is a graduate of The Los Angeles Film School, with a degree in graphic arts & music production.`,
					`"ChrisP” has collaborated with many prominent figures and has also created design projects for: Kia Shine (Artist & Actor), Kris D. Lofton (from Hit Tv Show “Power”), Desto Dubb (“That’s an Awful Lotta Cough Syrup” brand owner) Filayyyy (social media influencer & Athlete), Evvie McKinney (singer & songwriter), Phade (“Shirtkingphade” brand owner) , Live Nation Entertainment and many more! With a focus on continual self-improvement and leaning into giving back to his community, “ChrisP” is poised for success.`
				],
				weblink: "http://www.chrispbrand.com/",
				twitter: "",
				insta: "https://www.instagram.com/chrispbrand_",
				facebook: "",
				tiktok: "https://www.tiktok.com/@chrispbrand",
				youtube: "",
				img: "designers/kia_shine_chris_p/img/chrisp.jpg"
			}
		]
	}
].filter((i) => {
	Settings.defaultZone = new IANAZone("America/Chicago")

	// Get the current date and time.
	const currentDate = DateTime.local()

	// Create a DateTime object with and the specified string 'XXXX/XX/XX'.
	const targetDate = DateTime.fromFormat(`${i.datetime}`, "yyyy/MM/dd HH:mm")

	// Check if the target date is the same or in the future compared to the current date.
	return targetDate.startOf("day") >= currentDate.startOf("day")
})
